import React from "react";
import styled from "styled-components";
import styles from "../styles/footer.module.scss";

const ATEMFooter = () => {

  return (
    <Footer className={styles.container}>
      <div>
        <img className={styles.logo} src="/atemLogo.svg" alt="Beyond Logo" />
      </div>
      
      <div className={styles.copyBox}>
        <p>{"© Copyright ATEM Car Club 2022, All Rights Reserved"}</p>
      </div>
    </Footer>
  );
};

export default ATEMFooter;

const Footer = styled.div`
  position: relative;
  z-index: 2;
  padding-top: 32px;
`