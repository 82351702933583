import fetchJson from "../fetchJSON";

const SALT_API_URL = process.env.REACT_APP_MINT_API;

export default function client(url, init) {
  return fetchJson(SALT_API_URL + url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    ...init,
  });
}

export const retrievePublicSalt = async (message, signature) => {
  return await client("/api/salt", {
    method: "POST",
    body: JSON.stringify({ message, signature }),
  });
};

export const saleStatus = async () => {
  return await client("/salestatus", {
    method: "GET",
  });
};
