import { createContext, useContext, useEffect, useState } from "react";
import { retrieveSaleStatus } from "../utils/utils";

const GlobalContext = createContext({
  isOnWhitelist: false,
  setIsOnWhitelist: () => {},
  saleStatusHooks: {},
  setSaleStatusHooks: () => {},
  salt: null,
  setSalt: () => {},
  transactionProcessing: false,
  setTransactionProcessing: () => {},
  tx: "",
  setTx: () => {},
  walletConnectionError: "",
  setWalletConnectionError: () => {},
  headerWalletModalOpen: false,
  setHeaderModalOpen: () => {},
  totalSupply: 0,
  setTotalSupply: () => {},
  showConnectors: false,
  setShowConnectors: () => {}
});

const GlobalProvider = ({ children }) => {
  const [isOnWhitelist, setIsOnWhitelist] = useState(false);
  const [hasMintedWhitelist, setHasMintedWhitelist] = useState(false);
  const [salt, setSalt] = useState({});
  const [saleStatusHooks, setSaleStatusHooks] = useState({});
  const [transactionProcessing, setTransactionProcessing] = useState(false);
  const [tx, setTx] = useState("");
  const [walletConnectionError, setWalletConnectionError] = useState("");
  const [headerWalletModalOpen, setHeaderModalOpen] = useState("");
  const [totalSupply, setTotalSupply] = useState(0)
  const [showConnectors, setShowConnectors] = useState(false);

  useEffect(()=>{
    
    const getSaleStatus = async () => {
      const status = await retrieveSaleStatus()
      setSaleStatusHooks(status)
    }

    getSaleStatus()
    
  },[])

  const value = {
    saleStatusHooks,
    setSaleStatusHooks,
    isOnWhitelist,
    setIsOnWhitelist,
    hasMintedWhitelist,
    setHasMintedWhitelist,
    setSalt,
    salt,
    transactionProcessing,
    setTransactionProcessing,
    tx,
    setTx,
    walletConnectionError,
    setWalletConnectionError,
    headerWalletModalOpen,
    setHeaderModalOpen,
    totalSupply,
    setTotalSupply,
    showConnectors,
    setShowConnectors
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be within a GlobalContext Provider");
  }
  return context;
};

export { GlobalProvider, useGlobalContext };
