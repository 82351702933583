import { useWeb3React } from "@web3-react/core";
import Wallet from "../Wallet";

import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/header.module.scss"
import styled from "styled-components";

import * as API from "../../utils/utils";
import { useGlobalContext } from "../../context/GlobalContext";

/**
 * Default header component. This component is displayed on all pages and
 * contains the logo and navigation links. On small screens it displays the
 * button to open the MobileMenu.
 */
export default function Header() {
  const wallet = useWeb3React();
  const navigate = useNavigate();
  const { saleStatusHooks } = useGlobalContext();

  // const mintLive = saleStatusHooks.publicsale ? !true : !false;
  const mintLive = saleStatusHooks.publicsale ? true : false;

  const [showWallet, setShowWallet] = useState(false)
  
  const [onTrackBalance, setOnTrackBalance] = useState(0)
  const [atemBalance, setAtemBalance] = useState(0)
  const timer = useRef(null);


  useEffect(() => {
    
    const getBalances = async () => {

      const onTrackBalance = await API.getOnTrackBalance(wallet?.account)
      const atemBalance = await API.getAtemBalance(wallet?.account)

      setOnTrackBalance(onTrackBalance)
      setAtemBalance(atemBalance)
    }

    if (wallet?.isActive) {
      getBalances()
      timer.current = setInterval(() => {  
        getBalances()
      }, 1000 * 10);
    }

    return () => clearInterval(timer.current)
  }, [wallet?.isActive, wallet?.account])

  useEffect(() => {
    if (wallet?.isActive) {
      navigate("/claim");
    }
  }, [wallet?.isActive, navigate]);



  return (
    <>
      <header className="w-full grid grid-cols-3 align-center justify-items-center">
        <div></div>
        <div className={` ${styles.headerImageContainer}`}>
          <Link to="/">
            <img className={` ${styles.headerImg}`} src="/logo.svg" alt="Logo" />
          </Link>
        </div>
        <div className={`w-full top-0 right-1 flex justify-end relative`}>
          { mintLive && <><Balances className="flex-row">
            { wallet?.isActive && onTrackBalance !== 0 && 
              <div className="balance flex flex-col justify-center">
                <div className="value">{onTrackBalance}</div>
                <div className="collection">On Track</div>
              </div> }
            { wallet?.isActive && atemBalance !== 0 && 
              <div className="balance flex flex-col justify-center">
                <div className="value">{atemBalance}</div>
                <div className="collection">ATEM</div>
              </div> }
          </Balances> 
          <div className={`buttonWrapper relative`}>
            <DeskWrap>
              <Wallet setShowWallet={setShowWallet} address={wallet?.account} />
            </DeskWrap>
            <MobWrap>
              <MobButton className="mobButton" onClick={() => setShowWallet(!showWallet)}> 
                <img src="/atem-wallet.svg" alt="Wallet Icon" width="32" height="32" />
              </MobButton>
              {showWallet && <MobWalletWrap>
                <Wallet setShowWallet={setShowWallet} address={wallet?.account} />
              </MobWalletWrap> }
            </MobWrap>
          </div> </>}
        </div> 
      </header>
    </>
  );
}

const Balances = styled.div`
  align-items: center;
  color: #fff;
  line-height: 1;
  position: absolute;
  right: 16px;
  bottom: -8px;
  transform: translateY(100%);

  & .balance {
    padding: 4px;
    border: 1px solid #fff;
    border-radius: 0;
    margin-right: 8px;
    box-shadow: 0 0 8px 0 #fff;
  }

  & .value {
    line-height: 1;
    margin-bottom: 1px;
    text-align: right;
    font-size: 0.9rem;
  }
  
  & .collection {
    line-height: 1;
    font-size: 0.6rem;
    text-align: right;
  }
  display: flex;

  @media (max-width: 900px) {
    display: none;
  }
`

const DeskWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media (max-width: 900px) {
    display: none;
  }
`
  
const MobWrap = styled.div`
  display: none;
  position: relative;
  @media (max-width: 900px) {
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`

const MobButton = styled.div`
  height: 40px;
  width: 40px;
  padding: 8px;
  border: 1px solid #fff;
  box-shadow: 0 0 16px 0 #fff;
  background: #fff;
`

const MobWalletWrap = styled.div`
  position: absolute;
  transform: translateY(100%);
  right: -8px;
  z-index: 9;
`
