import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import Spinner from "./components/Spinner";

import "./styles/home.module.scss";
import Header from "./components/Header";
import ATEMFooter from "./components/AtemFooter";

const Home = React.lazy(() => import("./pages/Home"));
const Claim = React.lazy(() => import("./pages/Claim"));
const Checker = React.lazy(() => import("./pages/Checker"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

function App() {

  console.log(`REACT_APP_ENVIRONMENT: ${process.env.REACT_APP_ENVIRONMENT}`)

  return (

    <Layout>  
      <Suspense
        fallback={
          <div className="flex w-full h-full items-center justify-center">
            <Spinner size="lg" />
          </div>
        }
      >
        <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/checker" element={<Checker />} />
            <Route path="/claim" element={<Claim />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        <ATEMFooter />
      </Suspense>
    </Layout>
   
  );
}

export default App;
