import { ethers, BigNumber } from "ethers";
import { WalletConnect } from "@web3-react/walletconnect";

import { retrievePublicSalt } from "./apis/mintAPI";

import minterABI from "../abis/onTrackMinter.json";
import collectionABI from "../abis/ontrack.json";
import atemAbi from "../abis/atem.json";

const getHeaders = () => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

const extractBody = async (res) => {
  if (!res.ok) {
    throw new Error(await res.text());
  } else {
    return res.json();
  }
};

const PRICE_PER_NFT = BigNumber.from("001000000000000000");

// console.log('PRICE_PER_NFT', PRICE_PER_NFT)

const CONTRACT_ADDRESS = process.env.REACT_APP_ENVIRONMENT === 'dev' ? '0x45b81FF1eE46344a0918a0ef8704Aa9cBe7F4b86' : '0xD041EBF0246f622Cf0Da38A38e4Bc145aaB0eCB6';
const COLLECTION_ADDRESS = process.env.REACT_APP_ENVIRONMENT === 'dev' ? '0x0D3F6DA6aF87a00c76F6BC318835e8f5da35C7dD' : '0x40DdA1FE34Ddea62be817ff481378c0Cb54A93b4';
const ATEM_CONTRACT_ADDRESS = process.env.REACT_APP_ENVIRONMENT === 'dev' ? '0x8556fa947bA26961f30cF090C67d39b102D589AD' : '0x020cdc4775366ae436f13a7d333143432e884934';
export const MAX_TOKENS_TO_MINT = 10;
const MINT_API_URL = process.env.REACT_APP_MINT_API;
 
let provider, signer, contractProvider, contractSigner;

if (window?.ethereum) {

  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider?.getSigner();
  // console.log(abi)
  contractProvider = new ethers.Contract(CONTRACT_ADDRESS, minterABI, provider);
  contractSigner = new ethers.Contract(CONTRACT_ADDRESS, minterABI, signer);

} else {
  // INFURA FALLBACK RPC HERE
  let fallbackProvider = new ethers.providers.AlchemyProvider(process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'goerli' : 'homestead', process.env.REACT_APP_ALCHEMY_API);
  contractProvider = new ethers.Contract(
    CONTRACT_ADDRESS,
    minterABI,
    fallbackProvider
  );
}

export const sleep = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export const updateProviderAndSigner = (prov) => {
  if (prov) {
    provider = new ethers.providers.Web3Provider(prov);
  } else if (window?.ethereum) {
    provider = new ethers.providers.Web3Provider(window?.ethereum);
  }
  signer = provider?.getSigner();
  contractProvider = new ethers.Contract(CONTRACT_ADDRESS, minterABI, provider);
  contractSigner = new ethers.Contract(CONTRACT_ADDRESS, minterABI, signer);
};

export const getTotalSupply = async () => {
  if (contractProvider) {
    const supply = await contractProvider?.totalSupply();
    return supply?.toLocaleString("en-US");
  }
  return "?";
};

export const getAtemBalance = async (walletAddress) => { 
  
  // console.log('getAtemBalance', walletAddress, provider)
  const atemP = new ethers.Contract(ATEM_CONTRACT_ADDRESS, atemAbi, provider);
  const balance = await atemP?.balanceOf(walletAddress);
  return balance?.toLocaleString("en-US");
}

export const getOnTrackBalance = async (walletAddress) => { 
  
  // console.log('getOnTrackBalance', walletAddress, provider)
  const collectionContractProvider = new ethers.Contract(COLLECTION_ADDRESS, collectionABI, provider);
  const balance = await collectionContractProvider?.balanceOf(walletAddress);
  return balance?.toLocaleString("en-US");

}


export const signMessagePublic = async (
  walletToAuthenticate,
  provider,
  context
) => {
  const prov = provider?.toLowerCase();
  const message = `This signature is to prove you are minting from this official page. It is free of charge.`;
  let signature;

  if (prov === "coinbase") {
    await sleep(400);
  }

  if (["walletconnect", "coinbase"].includes(prov)) {
    signature = await context?.library?.provider?.send("personal_sign", [
      ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
      walletToAuthenticate?.toLowerCase(),
    ]);
  } else {
    signature = await signer?.signMessage(message);
  }
  console.log(signature, message)
  return retrievePublicSalt(message, signature);
};

export const doMint = async (tokens, qty) => {
  const totalAmount = PRICE_PER_NFT.mul(qty)
  
  let tx = await contractSigner.mintCollectionNft(tokens, {
    value: totalAmount
  });
  return tx;
};


export const retrieveClaimingSignature = async (walletAddress, tokenIds) => {
  return await fetch(`${MINT_API_URL}/signature`, {
    method: "POST",
    ...getHeaders(),
    body: JSON.stringify({ walletAddress, tokenIds }),
  }).then((response) => extractBody(response));
};

export const retrieveWhetherTokensHaveMinted = async (tokenIds) => {

  const signer = new ethers.Contract(CONTRACT_ADDRESS, minterABI, provider);
  const mintRes = await signer.getTokensMintedStatus(tokenIds);
  return mintRes;

};

export const getAtemTotalSupply = async () => {

  return await fetch(`${MINT_API_URL}/atemTotalSupply`, {
    method: "GET",
    ...getHeaders(),
  }).then((response) => extractBody(response));

};

export const retrieveWhetherTokensHaveMintedCheckerPage = async (tokenId) => {

  return await fetch(`${MINT_API_URL}/check`, {
    method: "POST",
    ...getHeaders(),
    body: JSON.stringify({tokenId: parseInt(tokenId)}),
  }).then((response) => extractBody(response));

};

export const retrieveSaleStatus = async () => {
  return await fetch(`${MINT_API_URL}/salestatus`, {
    method: "GET",
  }).then((response) => extractBody(response));
};

export const resetWalletConnector = (connector) => {
  if (
    connector &&
    connector instanceof WalletConnect &&
    (connector.walletConnectProvider?.wc?.uri ||
      connector.walletConnectProvider?.connector?.uri)
  ) {
    connector.walletConnectProvider = undefined;
  }
};

export function formatUnits(value, maxDecimals = 5) {
  if (!value) return "";
  const [left, right] = value.split(".");
  const formattedLeft = [...left]
    .reverse()
    .map((x, i) => (i && i % 3 === 0 ? `${x},` : x))
    .reverse()
    .join("");

  const formattedRight =
    !right || right === "0"
      ? ""
      : right?.length > maxDecimals
      ? right.slice(0, maxDecimals)
      : right;

  return [formattedLeft, formattedRight].filter(Boolean).join(".");
}

export const truncateAddress = (address, chars = 5) => {
  return `${address?.slice(0, chars)}...${address?.substring(
    address.length - 3,
    address?.length
  )}`;
};
