import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  getLastConnector,
  walletConnectors,
} from "../connectors";
import { updateProviderAndSigner } from "../utils/utils";
// import { useInactiveListener } from "../hooks/useInactiveListener";

/**
 * Default layout component. This layout is used for all pages.
 */
export default function Layout({ children }) {

  const [network, setNetwork] = useState()
  const wallet = useWeb3React();

  // useInactiveListener()

  useEffect(() => {

    const networkTest = async () => {
      if (window.ethereum) {
        if (window.ethereum.networkVersion !== 1 && process.env.REACT_APP_ENVIRONMENT !== 'dev') {
          walletConnectors[getLastConnector()]?.deactivate();

          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${process.env.REACT_APP_NETWORK_ID}` }], // chainId must be in hexadecimal numbers
          });
        }
        setNetwork(window.ethereum.networkVersion)
      }
    }

    networkTest()

  }, [])

  
  useEffect(() => {
    if (wallet?.isActive) {
      updateProviderAndSigner(wallet?.connector?.provider);
    }
  }, [wallet]);
  
  if (window.ethereum) {
    window.ethereum.on('networkChanged', async function(networkId){
      if (process.env.REACT_APP_ENVIRONMENT !== 'dev' && networkId === 5) {
        walletConnectors[getLastConnector()]?.deactivate();
      }
      window.location.reload()
    });
  }
  // console.log(wallet.chainId)

  return (
    <LayoutWrap>
      { ((wallet && wallet.chainId === 5) || (network && parseInt(network) === 5)) && <TestNetBanner>YOU ARE CURRENTLY ON TEST NET</TestNetBanner>}
      <div className="inner">{children}</div>
    </LayoutWrap>
  );
}

const TestNetBanner = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  text-align: center;
  background: red;
  padding: 16px;
  font-size: 0.8rem;
`


const LayoutWrap = styled.div`
  position: relative;
  z-index: 2;
  min-height: 100vh;
  
  & .inner {
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`