import { initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";

import { getItem, setItem } from "./hooks/useLocalStorage";

export const CONNECTOR_KEY = "LAST_WALLET_CONNECTOR";
export const WALLET_DISCONNECTED_KEY = "WALLET_DISCONNECTED";

export const POLLING_INTERVAL = 25000;
export const BALANCE_INTERVAL = 60000;

export const RPC_URLS = (process.env.REACT_APP_ENVIRONMENT === 'dev') ? { 5: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_API}` } : { 1: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_API}` };

export const setLastConnector = (connector) => {
  setItem(CONNECTOR_KEY, connector);
};

export const getLastConnector = () => {
  return getItem(CONNECTOR_KEY);
};

export const [metaMask, metaMaskHooks] = initializeConnector(
  (actions) => new MetaMask(actions)
);

export const [walletConnect, walletConnectHooks] = initializeConnector(
  (actions) =>
    new WalletConnect(actions, {
      rpc: RPC_URLS,
    }),
  Object.keys(RPC_URLS)?.map((chainId) => {
    return Number(chainId)
  })
);

export const [coinbaseWallet, coinbaseWalletHooks] = initializeConnector(
  (actions) =>
    new CoinbaseWallet(actions, {
      url: process.env.REACT_APP_ENVIRONMENT === 'dev' ? RPC_URLS[5] : RPC_URLS[1],
      appName: "atem-ontrack",
    })
);

export const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
  [coinbaseWallet, coinbaseWalletHooks],
];

export const walletConnectors = {
  metaMask: metaMask,
  Coinbase: coinbaseWallet,
  walletConnect: walletConnect,
};
