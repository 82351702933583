import styles from "../../styles/button.module.scss"

import { useGlobalContext } from "../../context/GlobalContext";

import {
  getLastConnector,
  setLastConnector,
  walletConnectors,
} from "../../connectors";
import { truncateAddress } from "../../utils/utils";
import { useWeb3React } from "@web3-react/core";

/**
 * ---WIP---
 * Component which displays information about the connected wallet. Is also the
 * wallet disconnect button.
 */
function Wallet({ address, setShowWallet }) {
  
  const { setShowConnectors, showConnectors  } = useGlobalContext();
  const wallet = useWeb3React();

  const handleDisconnect = () => {
    try {
      setShowWallet(false)
      setShowConnectors(false)
      walletConnectors[getLastConnector()]?.deactivate();
      setLastConnector(null);
    } catch (e) {
      console.error(e);
    }
  };
  
  return (
    <>
      {
        !wallet.isActive ? 
        <div
          className={`${styles.button} p-2 m-2 cursor-pointer text-center`}
          onClick={() => { setShowWallet(false); setShowConnectors(!showConnectors)} }
        >
          <span className="inline-block">{showConnectors ? "Cancel" : "Connect Wallet"}</span>
        </div>
        :
        <div
          className={`${styles.button} group p-2 m-2 cursor-pointer text-center`}
          onClick={handleDisconnect}
        >
          <span className="hidden group-hover:inline-block">Disconnect</span>
          <span className="inline-block group-hover:hidden">
            {truncateAddress(address)}
          </span>
        </div>
      }
    </>
    
  );
}


export default Wallet;
